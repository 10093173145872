:root{
    --color-primary: #f1132a;
    --color-gray: #4c4c4c;
}
.container-main{
    min-height: calc(100vh - (26.5px + 80px + 250px))
}
.t-text-primary{
    color: var(--color-primary) !important;
}
.t-text-gray{
    color: var(--color-gray) !important;
}
.t-bg-primary{
    background-color: var(--color-primary) !important;
}
.t-bg-gray{
    background-color: var(--color-gray) !important;
}
.carousel-custom .swiper-button-next,
.carousel-custom .swiper-button-prev{
    background-color: rgba(50, 50, 50, 0.35);
    border-radius: 7px;
    transition: all 0.25s;
    color: white;
}
.carousel-custom .swiper-button-next:hover,
.carousel-custom .swiper-button-prev:hover{
    background-color: rgba(50, 50, 50, 1);
}
.carousel-custom .swiper-button-next::after,
.carousel-custom .swiper-button-prev::after{
    font-size: 24px;
}

.carousel-custom .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active{
    background-color: white;
    box-shadow: 0.5px 0.5px 1px 0px gray;
}

#content_drawmenu .MuiDrawer-paper{
    background-color: var(--color-gray);
}
#content_drawmenu .MuiBox-root{
    width: 290px;
}
#content_drawmenu .MuiButtonBase-root.MuiListItemButton-root p{
    padding: 0.5rem 0;
}
#content_drawmenu .MuiListItem-root{
    border-bottom: 0.75px solid rgba(150, 150, 150, 0.25);
}
#content_drawmenu .MuiButtonBase-root.MuiListItemButton-root:hover,
#content_drawmenu .MuiButtonBase-root.MuiListItemButton-root.active,
#content_drawmenu .MuiButtonBase-root.MuiListItemButton-root:focus{
    background-color: var(--color-primary);
}

.content-menu-category .MuiAccordionSummary-content{
    margin: 0;
    width: 100%;
}
.content-menu-category .MuiButtonBase-root.MuiMenuItem-root{
    padding-top: 0;
    padding-bottom: 0;
}
.MuiList-root.MuiMenu-list:has(.custom-search-haed){
    max-height: 200px !important;
    /* background-color: red !important; */
}
#select-dd-custom-des.MuiSelect-select, #select-dd-custom-mobile.MuiSelect-select{
    color: #727272;
}
.t-btn-link-category{
    color: #222222;
    font-weight: 600 !important;
    transition: all 0.25s;
    font-size: 14px !important;
    cursor: pointer;
}
.t-btn-link-category:hover{
    color: var(--color-primary);
    text-decoration: underline;
}
img.t-img-fluid, .t-img-fluid > img{
    max-width: 100%;
    height: auto;
}
.t-thumbs-preview .t-img-fluid > img{
    cursor: zoom-in !important;
}
.t-thumbs-preview [data-rmiz-content="found"] > img.t-img-fluid{
    object-fit: none;
    width: 100%;
    cursor: zoom-in !important;
}
/* zoom image product */
.t-thumbs-preview{
    margin-bottom: 1rem;
}
.t-thumbs-gallery .swiper-slide.swiper-slide-visible::before{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 99.99%;
    height: 97%;
    background-color: rgba(150, 150, 150, 0.55);
    box-shadow: 0 0 0 4px inset #0000;
    cursor: pointer;
    transition: all 0.25s;
}
.t-thumbs-gallery .swiper-slide.swiper-slide-visible.swiper-slide-thumb-active::before{
    background-color: rgba(150, 150, 150, 0);
    box-shadow: 0 0 0 4px inset var(--color-primary);
    border-radius: 6px;
}
.t-thumbs-gallery .swiper-wrapper > .swiper-slide > img{
    width: 100%;
    height: 130px;
    object-fit: contain;
}


/* list menu category */
.t-custom-listmenu .MuiTreeItem-content{
    width: auto;
    padding: 0rem .35rem;
    /* border-radius: 0 1rem 1rem 0; */
    margin-bottom: 0.25rem;
    background-color: #0000 !important;
}
.t-custom-listmenu .MuiTreeItem-label .MuiTypography-root{
    font-size: 16px;
    color: #222222;
}
.t-custom-listmenu .MuiTreeItem-content.Mui-focused.Mui-expanded,
.t-custom-listmenu .MuiTreeItem-content.Mui-selected,
.t-custom-listmenu .MuiTreeItem-content.Mui-selected.Mui-expanded{
    box-shadow: 0 2px 0 0 var(--color-primary);
}
.t-custom-listmenu .MuiTreeItem-content.Mui-expanded + ul{
    border-left: 2px dotted #eee;
}
.t-custom-listmenu .t-text-listmenu{
    color: var(--color-gray);
    font-weight: 400;
}
.t-custom-listmenu .MuiTreeItem-content.Mui-selected.Mui-expanded .t-text-listmenu,
.t-custom-listmenu .MuiTreeItem-content.Mui-focused.Mui-expanded .t-text-listmenu{
    color: var(--color-primary);
    font-weight: 600;
}

/* card product */
.t-img-product{
    width: 100%;
    height: 230px;
    object-fit: contain;
}
.t-img-product.list{
    width: 100%;
    height: 230px;
    object-fit: contain;
}
.t-text-wrap-3{
    line-height: 1.8 !important;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}

.t-brand-link.MuiButtonBase-root.MuiMenuItem-root{
    margin-bottom: .5rem;
    border-radius: .15rem;
    padding: .25rem .5rem;
    color: var(--color-gray);
}
.t-brand-link.MuiButtonBase-root.MuiMenuItem-root:hover{
    background-color: #0000;
    color: var(--color-primary);
}
.t-brand-link.MuiButtonBase-root.MuiMenuItem-root .MuiTypography-root{
    font-size: 16px;
    text-transform: uppercase;
}

/* unput multi selected */
.t-multiselect .MuiInputBase-root.Mui-focused fieldset.MuiOutlinedInput-notchedOutline{
    border-color: var(--color-primary) !important;
}
.t-multiselect label.MuiInputLabel-root.Mui-focused{
    color: var(--color-primary) !important;
}

/* img detail product */
.t-img-detailproduct img{
    width: 100%;
    height: 430px;
    object-fit: scale-down;
}

.t-submenu-list .border-list-item .text-typo{
    font-size: 15px;
}
.t-submenu-list .border-list-item{
    /* border-left: 1px solid #dfdfdf; */
}
.t-submenu-list .border-list-item .text-typo{
    padding-left: .65rem;
}
.link-category{
    color: var(--color-gray);
    text-decoration: none;
}
.link-category:hover, .link-category.active{
    color: var(--color-primary);
}
.t-border-bottom{
    /* padding: .45rem 0; */
    border-bottom: 1px solid #efefef;
}
.items-list:last-child .t-border-bottom{
    border-bottom: none;
}
.t-submenu-list .t-border-bottom{
    padding: 0;
    border-bottom: none;
}
/* animated */
@keyframes fadeLeftShow{
    from{
        opacity: 0;
        transform: translateX(-10px);
        width: 0px;
    }
    to{
        opacity: 1;
        transform: translateY(0px);
        width: auto;
    }
}
@-webkit-keyframes fadeLeftShow{
    from{
        opacity: 0;
        transform: translateX(-10px);
        width: 0px;
    }
    to{
        opacity: 1;
        transform: translateY(0px);
        width: auto;
    }
}