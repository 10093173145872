@media (max-width: 1440px) {
    .t-button-navbar{
        font-size: 12px !important;
    }
}
@media (max-width: 1366px) {
    .t-thumbs-preview [data-rmiz-content="found"]{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .t-thumbs-preview [data-rmiz-content="found"] > img.t-img-fluid {
        max-height: 260px;
        max-width: 400px;
        object-fit: scale-down;
    }
    .t-thumbs-gallery .swiper-wrapper > .swiper-slide > img {
        width: 100%;
        height: 110px;
        object-fit: contain;
    }
}

/* Phone */
@media (max-width: 765px) {
    .t-thumbs-gallery .swiper-wrapper > .swiper-slide > img {
        width: 100%;
        height: 70px;
        object-fit: contain;
    }
    .t-img-product{
        height: 160px;
        object-fit: contain;
    }
    .image-promotion-app{
        height: auto !important;
    }
}