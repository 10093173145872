@import url("https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
body {
  margin: 0;
  font-family: "Prompt", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Prompt", sans-serif;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 20px;
  background: #d0d5da;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #f1132a;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #eb5765;
}

@media (min-width: 1400px) {
  .link-home-social > .MuiGrid-grid-lg-3 {
    -webkit-flex-basis: calc(100% / 5);
    -ms-flex-preferred-size: calc(100% / 5);
    flex-basis: calc(100% / 5);
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: calc(100% / 5);
  }
}
.blink_me {
  animation: blinker 1.7s linear infinite;
  margin: auto;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
